// switch for internal development
//export const requestUrl = 'https://c-air.siitgis.com/';

// switch for cloud development

// switch for deploy
export const requestUrl = '/';
export const mapRequestUrl = 'https://c-air.siitgis.com/';

const session = localStorage.getItem('session storage');
export const checkSession = session === null ? false : true;

//get prov
export const API_GET_AQI = requestUrl + 'point2aqi.php';
export const API_GET_STATION = requestUrl + 'stations.php';

// map layers
export const API_MAPLAYER =
  mapRequestUrl + 'cgi-bin/mapserver.fcgi?map=wms.map';
