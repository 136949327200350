import React from "react";
import {
  Table
} from "reactstrap";  
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import "./Info.css"
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Refresh from "@material-ui/icons/Refresh";
// import Edit from "@material-ui/icons/Edit";
// import Place from "@material-ui/icons/Place";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts";

import SASS_icon from "assets/cairassets/img/logo-sss.png";
import NET_icon from "assets/cairassets/img/logo-net.png";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

// import priceImage1 from "assets/img/card-2.jpeg";
// import priceImage2 from "assets/img/card-3.jpeg";
// import priceImage3 from "assets/img/card-1.jpeg";

// const us_flag = require("assets/img/flags/US.png");
// const de_flag = require("assets/img/flags/DE.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");
// const ro_flag = require("assets/img/flags/RO.png");
// const br_flag = require("assets/img/flags/BR.png");

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card product className={classes.cardHover}>
            <CardBody>
            <h2  style={{paddingBottom:20}}>
                ตารางคำแนะนำสำหรับประชาชนในการปกป้องกันสุขภาพจากฝุ่น PM<sub><small>2.5</small></sub> 
            
              </h2>
            <Table>
              <thead>
                <tr>  
                  <th scope="row" style={{textAlign: 'center'}}>PM<sub><small>2.5</small></sub>  (มคก./ลบ.ม.)</th>
                  <th style={{textAlign: 'center'}}>ประชาชนทั่วไป</th>
                  <th style={{textAlign: 'center'}}>
                    <div>ผู้ที่มีความไวต่อการได้รับ</div>
                    <div>ผลกระทบสุขภาพ (“กลุ่มเสี่ยง”)</div>
                  </th>
                  <th style={{textAlign: 'center'}}>ข้อความแนะนำสุขภาพ</th>
                </tr>
              </thead>
              <tbody>
                {/* ------------------------------ Row 1 ------------------------ */}
                <tr>
                  <th scope="row" style={{textAlign: 'center',backgroundColor:"#66C5FF"}}>
                    <div>
                      0 – 25
                    </div>
                    <div>   
                      "ดีมาก"
                    </div>
                  </th>
                  <td>
                    <ul>
                      <li>ทำกิจกรรมกลางแจ้งได้ตามปกติ</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>ทำกิจกรรมกลางแจ้งได้ตามปกติ</li>
                    </ul>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div>
                      คุณภาพอากาศดี
                    </div>
                    <div>
                      (Good)
                    </div>
                  </td>
                </tr>
                {/* ------------------------------ Row 2 ------------------------ */}
                <tr>
                  <th scope="row" style={{textAlign: 'center',backgroundColor:"#99CF68"}}>
                    <div>
                      26 - 37
                    </div>
                    <div>   
                      "ดี"
                    </div>
                  </th>
                  <td>
                    <ul>
                      <li>ทำกิจกรรมกลางแจ้งได้ตามปกติ</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>หลีกเลี่ยงการการออกกำลังกายกลางแจ้ง</li>
                      <li>เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div>
                      คุณภาพอากาศปานกลาง
                    </div>
                    <div>
                      (Moderate)
                    </div>
                  </td>
                </tr>
                {/* ------------------------------ Row 3 ------------------------ */}
                <tr>
                  <th scope="row" style={{textAlign: 'center',backgroundColor:"#FFFE54"}}>
                    <div>
                      38– 50
                    </div>
                    <div>   
                      "ปานกลาง"
                    </div>
                  </th>
                  <td>
                    <ul>
                      <li> ควรหลีกเลี่ยงการออกกำลังกายกลางแจ้ง</li>
                      <li> เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                        <li> หลีกเลี่ยงการทำกิจกรรมกลางแจ้ง</li>
                        <li> งดออกกำลังกายกลางแจ้ง</li>
                        <li> สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</li>
                        <li> เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div>
                        ไม่ปลอดภัยต่อสุขภาพของกลุ่มเสี่ยง
                    </div>
                    <div>
                      (Unhealthy for sensitive group)
                    </div>
                  </td>
                </tr>
                {/* ------------------------------ Row 4 ------------------------ */}
                <tr>
                  <th scope="row" style={{textAlign: 'center',backgroundColor:"	#F3C041"}}>
                    <div>
                      51 – 90
                    </div>
                    <div>   
                      "เริ่มมีผลกระทบต่อสุขภาพ"
                    </div>
                  </th>
                  <td>
                    <ul>
                        <li> หลีกเลี่ยงการทำกิจกรรมกลางแจ้ง</li>
                        <li> งดออกกำลังกายกลางแจ้ง</li>
                        <li> สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</li>
                        <li> เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li> ลดหรืองดการทำกิจกรรมกลางแจ้ง</li>
                      <li> งดการออกกำลังกายกลางแจ้ง</li>
                      <li> สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</li>
                      <li> เฝ้าระวังหรือสังเกตอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div>
                        ไม่ปลอดภัยต่อสุขภาพ
                    </div>
                    <div>
                      (Unhealthy) 
                    </div>
                  </td>
                </tr>
                {/* ------------------------------ Row 5 ------------------------ */}
                <tr>
                  <th scope="row" style={{textAlign: 'center',backgroundColor:"#E73425"}}>
                    <div>
                      91 ขึ้นไป
                    </div>
                    <div>   
                      "มีผลกระทบต่อสุขภาพ"
                    </div>
                  </th>
                  <td>
                    <ul>
                      <li> ลดหรืองดการทำกิจกรรมกลางแจ้ง</li>
                      <li> งดการออกกำลังกายกลางแจ้ง</li>
                      <li> สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</li>
                      <li> เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>งดการทำกิจกรรมกลางแจ้ง</li>
                      <li>งดการออกกำลังกายกลางแจ้ง</li>
                      <li>อยู่ในอาคารที่มีระบบกรองฝุ่น หรือในห้องปลอดฝุ่น</li>
                      <li>ถ้าจำเป็นต้องออกนอกอาคาร สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub> </li>
                      <li>เฝ้าระวังและดูแลอาการผิดปกติ</li>
                    </ul>
                  </td>
                  <td style={{textAlign: 'center'}}>
                    <div>
                      ไม่ปลอดภัยต่อสุขภาพอย่างมาก
                    </div>
                    <div>
                      (Very   Unhealthy) 
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
              {/* <h3 className={classes.cardProductTitle}>
                <a href="/#" onClick={e => e.preventDefault()}>
                  ตารางคำแนะนำสำหรับประชาชนในการปกป้องกันสุขภาพจากฝุ่นPM<sub><small>2.5</small></sub> 
                </a>
              </h3>
              <p className={classes.cardProductDesciprion}>
              <table class="blueTable" width="605">
              <thead>
              <tr>
              <td width="85">
              <p><strong>พีเอ็ม </strong><strong>2.5 </strong></p>
              <p><strong>(มคก.</strong><strong>/ลบ.ม.)</strong></p>
              </td>
              <td width="208">
              <p><strong>ประชาชนทั่วไป</strong></p>
              </td>
              <td width="198">
              <p><strong>ผู้ที่มีความไวต่อการได้รับผลกระทบสุขภาพ (&ldquo;กลุ่มเสี่ยง&rdquo;)</strong></p>
              </td>
              <td width="113">
              <p><strong>ข้อความแนะนำสุขภาพ</strong></p>
              </td>
              </tr>
              </thead>
              <tbody>
              <tr style={{backgroundColor:'#66C7FC'}} >
                <td width="85">
                <p>0 &ndash; 25</p>
                <p>&ldquo;ดีมาก&rdquo;</p>
                </td>
                <td width="208">
                <p>-&nbsp;&nbsp; ทำกิจกรรมกลางแจ้งได้ตามปกติ</p>
                </td>
                <td width="198">
                <p>-&nbsp;&nbsp; ทำกิจกรรมกลางแจ้งได้ตามปกติ</p>
                </td>
                <td width="113">
                <p>คุณภาพอากาศดี</p>
                <p>(Good)</p>
                </td>
              </tr>
              <tr style={{backgroundColor:'#9ECE5F'}}>
                <td width="85">
                <p>26 &ndash; 37</p>
                <p>&ldquo;ดี&rdquo;</p>
                </td>
                <td width="208">
                <p>-&nbsp;&nbsp; ทำกิจกรรมกลางแจ้งได้ตามปกติ</p>
                </td>
                <td width="198">
                <p>-&nbsp;&nbsp; หลีกเลี่ยงการการออกกำลังกายกลางแจ้ง</p>
                <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
                </td>
                <td width="113">
                <p>คุณภาพอากาศปานกลาง</p>
                <p>(Moderate)</p>
                </td>
              </tr>
              <tr style={{backgroundColor:'#FFFE56'}} >
              <td width="85">
              <p>38 &ndash; 50</p>
              <p>&ldquo;ปานกลาง&rdquo;</p>
              <p>&nbsp;</p>
              </td>
              <td width="208">
              <p>-&nbsp;&nbsp; ควรหลีกเลี่ยงการออกกำลังกายกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
              </td>
              <td width="198">
              <p>-&nbsp;&nbsp; หลีกเลี่ยงการทำกิจกรรมกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; งดออกกำลังกายกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</p>
              <p>-&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
              </td>
              <td width="113">
              <p>ไม่ปลอดภัยต่อสุขภาพของกลุ่มเสี่ยง</p>
              <p>(Unhealthy for sensitive group)</p>
              </td>
              </tr>
              <tr style={{backgroundColor:'#FBC143'}}>
              <td width="85">
              <p>51 &ndash; 90</p>
              <p>&ldquo;เริ่มมีผลกระทบต่อสุขภาพ&rdquo;</p>
              <p>&nbsp;</p>
              </td>
              <td width="208">
              <p>-&nbsp;&nbsp; หลีกเลี่ยงการทำกิจกรรมกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; หลีกเลี่ยงการออกกำลังกายกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</p>
              <p>-&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
              </td>
              <td width="198">
              <p>-&nbsp;&nbsp; ลดหรืองดการทำกิจกรรมกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; งดการออกกำลังกายกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</p>
              <p>-&nbsp;&nbsp; เฝ้าระวังหรือสังเกตอาการผิดปกติ</p>
              </td>
              <td width="113">
              <p>ไม่ปลอดภัยต่อสุขภาพ</p>
              <p>(Unhealthy)</p>
              </td>
              </tr >
              <tr style={{backgroundColor:'#E93422'}}>
              <td width="85">
              <p>91 ขึ้นไป</p>
              <p>&ldquo;มีผลกระทบต่อสุขภาพ&rdquo;</p>
              <p>&nbsp;</p>
              </td>
              <td width="208">
              <p>-&nbsp;&nbsp; ลดหรืองดการทำกิจกรรมกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; งดการออกกำลังกายกลางแจ้ง &nbsp;</p>
              <p>-&nbsp;&nbsp; สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub>  เมื่ออยู่นอกอาคาร</p>
              <p>-&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
              </td>
              <td width="198">
              <p>-&nbsp;&nbsp; งดการทำกิจกรรมกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; งดการออกกำลังกายกลางแจ้ง</p>
              <p>-&nbsp;&nbsp; อยู่ในอาคารที่มีระบบกรองฝุ่น หรือในห้องปลอดฝุ่น</p>
              <p>-&nbsp;&nbsp; ถ้าจำเป็นต้องออกนอกอาคาร สวมหน้ากากกันฝุ่นPM<sub><small>2.5</small></sub> </p>
              <p>-&nbsp;&nbsp; เฝ้าระวังและดูแลอาการผิดปกติ</p>
              </td>
              <td width="113">
              <p>ไม่ปลอดภัยต่อสุขภาพอย่างมาก</p>
              <p>(Very Unhealthy)</p>
              </td>
              </tr>
              </tbody>
              </table>
              </p> */}
            </CardBody>
            {/* <CardFooter product>
              <div className={classes.price}><h4>$459/night</h4></div>
              <div className={`${classes.stats} ${classes.productStats}`}>
                https://thailandcan.org/about
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
       
      </GridContainer>
    </div>
  );
}
