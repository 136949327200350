import React from "react";
// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import Tooltip from "@material-ui/core/Tooltip";
// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
// import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
// import Warning from "@material-ui/icons/Warning";
// import DateRange from "@material-ui/icons/DateRange";
// import LocalOffer from "@material-ui/icons/LocalOffer";
// import Update from "@material-ui/icons/Update";
// import ArrowUpward from "@material-ui/icons/ArrowUpward";
// import AccessTime from "@material-ui/icons/AccessTime";
// import Refresh from "@material-ui/icons/Refresh";
// import Edit from "@material-ui/icons/Edit";
// import Place from "@material-ui/icons/Place";
// import ArtTrack from "@material-ui/icons/ArtTrack";
// import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Table from "components/Table/Table.js";
// import Button from "components/CustomButtons/Button.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts";

import SASS_icon from "assets/cairassets/img/logo-sss.png";
import NET_icon from "assets/cairassets/img/logo-net.png";
import SIIT_icon from "assets/cairassets/img/logo-siit.png";
//import SIIT_icon from "assets/img/logo-siit2.png";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

// import priceImage1 from "assets/img/card-2.jpeg";
// import priceImage2 from "assets/img/card-3.jpeg";
// import priceImage3 from "assets/img/card-1.jpeg";

// const us_flag = require("assets/img/flags/US.png");
// const de_flag = require("assets/img/flags/DE.png");
// const au_flag = require("assets/img/flags/AU.png");
// const gb_flag = require("assets/img/flags/GB.png");
// const ro_flag = require("assets/img/flags/RO.png");
// const br_flag = require("assets/img/flags/BR.png");

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card product className={classes.cardHover}>
            <CardBody>
              <h3 className={classes.cardProductTitle}>
                <a href="/#" onClick={e => e.preventDefault()}>
                  CLEAN AIR NETWORK THAILAND
                </a>
              </h3>
              <p className={classes.cardProductDesciprion}>
                Although the health dangers of air pollution is known globally
                over many years, the level of public awareness in Thailand is at
                a very nascent state. It was ignited only in early 2019 with the
                air pollution episodes in Bangkok and in many regions of
                Thailand, where the PM<sub><small>2.5</small></sub> (or Particulate Matter 2.5) levels
                exceeded thresholds considered safe for human health. This
                situation deteriorated to the point that, by February 12, 2019,
                the Thai Government declared the fight against air pollution to
                be on the national agenda. The Thai northern haze, an occurrence
                prevalent for more than a decade, and has not, unfortunately,
                garnered as much public attention, also deteriorated
                significantly earlier in the year. Both of these air pollution
                episodes catapulted public awareness and concerns about the
                polluted air that we are breathing in for the sustenance of our
                lives and the related health impact.
              </p>
              <p
                className={classes.cardProductDesciprion}
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  marginTop: "25px",
                  marginBottom: "25px"
                }}
              >
                “The PM<sub><small>2.5</small></sub> (or Particulate Matter 2.5) levels exceeded
                thresholds considered safe for human health.”
              </p>
              <p className={classes.cardProductDesciprion}>
                In the midst of this situation, besides governmental agencies,
                people from academia, professional bodies and civil society have
                all stepped forward to publicly disseminate knowledge and
                suggest solutions. Many groups have been formed to jointly
                analyze the situation and to offer short-term, medium-term and
                long-term solutions through a myriad of public events. However,
                one grassroots citizen group has been actively following this
                situation since early 2018, driven by the grave concern about
                the inadequacy of the official response to the air pollution
                episode in Bangkok since late 2017. The group took measures into
                their own hands to gain a deeper understanding of the situation
                and attempted to solicit interest through official channels, but
                the effort fell short then in gaining traction. However, when
                the air pollution episode re-occurred in Bangkok in early 2019,
                the group was able to swiftly disseminate a significant body of
                knowledge publicly on a regular basis through a Facebook page
                called “ThaiPrompt.” Furthermore, it galvanized support from
                academic professionals from various disciplines to collaborate
                on a voluntary basis and organized eight public information
                forums with partial support from the Thai Public Health
                Foundation, the Thai National Health Commission Office and the
                Thai Public Broadcasting Service. Through these public forums,
                alongside numerous private discussion sessions, the group was
                able to expand its reach into a broader network that now
                includes over 60 Thai organizations as well as a number of
                international bodies. This broader group regularly exchanges
                knowledge and knowhow on air pollution issues through social
                media chat groups with membership that now number in the
                hundreds. This group is called the “Thailand Clean Air Network.”
              </p>
            </CardBody>
            {/* <CardFooter product>
              <div className={classes.price}><h4>$459/night</h4></div>
              <div className={`${classes.stats} ${classes.productStats}`}>
                https://thailandcan.org/about
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card product className={classes.cardHover}>
            <CardBody>
              <h3 className={classes.cardProductTitle}>
                <a href="/#" onClick={e => e.preventDefault()}>
                  เครือข่ายอากาศสะอาด
                </a>
              </h3>
              <p className={classes.cardProductDesciprion}>
                มลพิษทางอากาศ (Air Pollution)
                แม้เป็นภัยคุกคามสุขภาพที่ประชาคมโลกให้ความสำคัญอย่างมากในช่วงหลายปีที่ผ่านมา
                แต่นับเป็นเรื่องใหม่สำหรับสังคมไทย
                ที่เพิ่งเกิดความตื่นตัวกันอย่างมากในช่วงต้นปี พ.ศ. 2562
                เนื่องจากสถานการณ์วิกฤตคุณภาพอากาศในกรุงเทพมหานครและหลายพื้นที่ของประเทศ
                มีปริมาณของ ฝุ่นละอองขนาดเล็กกว่าหรือเท่ากับ 2.5 ไมครอน หรือ
                “PM<sub><small>2.5</small></sub>” สูงจนอยู่ในระดับที่เป็นอันตรายร้ายแรงต่อสุขภาพ
                จนกระทั่งรัฐบาลได้ประกาศให้ปัญหาฝุ่นPM<sub><small>2.5</small></sub>
                เป็นวาระแห่งชาติในวันที่12 กุมภาพันธ์ 2562
                ในขณะที่ปัญหาหมอกควันในพื้นที่ภาคเหนือที่ไม่ได้รับความสนใจจากสังคมไทยโดยรวมมากนักแม้เป็นปัญหาต่อเนื่องยาวนานมากกว่าสิบปี
                มาในปี 2562 ก็มีความรุนแรงและยาวนานมากกว่าหลายปีที่ผ่านมา
                จึงเป็นเหตุประกอบกันทำให้สังคมไทยตื่นตัวมากขึ้นกับปัญหาของคุณภาพอากาศใช้หายใจอยู่ทุกเมื่อเชื่อวัน
                โดยเฉพาะความวิตกกังวลถึงผลกระทบต่อสุขภาพจากการหายใจอากาศที่ปนเปื้อนเข้าสู่ร่างกาย
              </p>
              <p
                className={classes.cardProductDesciprion}
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  marginTop: "25px",
                  marginBottom: "25px"
                }}
              >
                “วิกฤตคุณภาพอากาศในกรุงเทพมหานครและหลายพื้นที่ของประเทศ
                มีปริมาณของ ฝุ่นละอองขนาดเล็กกว่าหรือเท่ากับ 2.5 ไมครอน หรือ
                “PM<sub><small>2.5</small></sub>” สูงจนอยู่ในระดับที่เป็นอันตรายร้ายแรงต่อสุขภาพ”
              </p>
              <p className={classes.cardProductDesciprion}>
                ท่ามกลางสถานการณ์ดังกล่าว นอกจากหน่วยงานรัฐแล้ว ยังมีนักวิชาการ
                บุคลากรวิชาชีพ
                และภาคประชาชนออกมาให้ความรู้และให้ข้อเสนอแนะเพื่อการจัดการปัญหา
                รวมถึงมีการรวมตัวกันเพื่อร่วมกันคิดและทำความเข้าใจกับปัญหาและหาทางออกทั้งระยะสั้น
                ระยะกลาง และระยะยาวอย่างยั่งยืน
                มีการจัดเวทีระดมความคิดเห็นจากหน่วยงานและสถาบันต่าง ๆ
                เป็นจำนวนมาก
                อย่างไรก็ตามมีภาคประชาสังคมกลุ่มหนึ่งที่มีความตื่นตัวในเรื่องมลพิษทางอากาศมาตั้งแต่ปี
                พ.ศ. 2561
                ที่ความไม่ชัดเจนของภาครัฐในการออกมาตรการแก้ไขที่เป็นรูปธรรมและเหมาะสมต่อปัญหามลพิษทางอากาศในกรุงเทพมหานคร
                ได้ทำให้เกิดการรวมตัวกันนำมาสู่การศึกษาและการสร้าง Facebook Page
                ชื่อ “ไทยพร้อม”
                สำหรับเผยแพร่ข่าวสารและความรู้เกี่ยวกับมลพิษทางอากาศอย่างต่อเนื่อง
                เมื่อเกิดสถานการณ์วิกฤติของมลพิษทางอากาศในช่วงต้นปี พ.ศ. 2562
                จึงได้มีการขยายเครือข่ายไปสู่
                สถาบันหน่วยงานในทุกภาคส่วนรวมทั้งองค์กรต่างประเทศ
                โดยได้ขยายความร่วมมือเป็นกลุ่มของภาคประชาสังคมและนักวิชาการที่รวมตัวกันทำงานแบบจิตอาสา
                มีการจัดเวทีสาธารณะอย่างต่อเนื่อง ทั้งในกรุงเทพและต่างจังหวัด
                โดยใช้ชื่อว่า “ไทยพร้อมล้อมวง” จำนวนกว่า 8 ครั้งในช่วงที่ผ่านมา
                โดยมีผู้สนับสนุนหลัก คือ
                สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)
                สำนักงานคณะกรรมการสุขภาพแห่งชาติ (สช.) และไทยพีบีเอส
                ซึ่งการจัดเวทีดังกล่าวและการจัดวงคุยย่อยอีกหลายครั้ง
                ได้ทำให้เกิดเครือข่ายการทำงาน ที่มีการขยายออกไปอย่างต่อเนื่อง
                จนถึงปัจจุบันมีจำนวนภาคีมากกว่า 60 องค์กร และมีการทำงาน
                แลกเปลี่ยนข้อมูลความรู้ผ่านกลุ่มไลน์หลายกลุ่มที่บางกลุ่มมีสมาชิกหลายร้อยคน
                เพื่อทำความเข้าใจ
                และหาแนวทางแก้ปัญหามลพิษทางอากาศในประเทศไทยอย่างยั่งยืน
                โดยตั้งชื่อเครือข่ายว่า “เครือข่ายอากาศสะอาด”
              </p>
            </CardBody>
            <CardFooter product>
              <div className={classes.price}>
                <h4>© THAILANDCAN 2019</h4>
              </div>
              <div className={`${classes.stats} ${classes.productStats}`}>
                <a href="https://thailandcan.org/about/">
                  <img
                    src={NET_icon}
                    alt="..."
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "42px",
                      boxShadow:
                        "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)"
                    }}
                  />
                </a>
                <a href="https://www.thaihealth.or.th/">
                  <img
                    src={SASS_icon}
                    alt="..."
                    style={{
                      marginRight: "15px",
                      width: "84px",
                      height: "84px",
                      borderRadius: "42px",
                      boxShadow:
                        "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)"
                    }}
                  />
                </a>
                      <a href="https://siitgis.com">
                        <img
                          src={SIIT_icon}
                          alt="..."
                          style={{
                            position:'relative',
                            width: "84px",
                            height: "84px",
                            borderRadius: "42px",
                            boxShadow:
                              "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)"
                          }}
                        />
                      </a>
                
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
