/* eslint-disable no-redeclare */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-string-refs */
import React from 'react';
import moment from 'moment';
// import watermark_logo from "assets/cairassets/img/leaflet.png";

import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-groupedlayercontrol';
import 'leaflet.markercluster';
import 'leaflet-easybutton';
import 'leaflet-search';
import 'leaflet-search/src/leaflet-search.css';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import AIR_icon00 from 'assets/cairassets/img/air-0.png';
import AIR_icon01 from 'assets/cairassets/img/air-1.png';
import AIR_icon02 from 'assets/cairassets/img/air-2.png';
import AIR_icon03 from 'assets/cairassets/img/air-3.png';
import AIR_icon04 from 'assets/cairassets/img/air-4.png';
import AIR_icon05 from 'assets/cairassets/img/air-5.png';

// import STATION_icon00 from 'assets/cairassets/img/station-0.png';
// import STATION_icon01 from 'assets/cairassets/img/station-1.png';
// import STATION_icon02 from 'assets/cairassets/img/station-2.png';
// import STATION_icon03 from 'assets/cairassets/img/station-3.png';
// import STATION_icon04 from 'assets/cairassets/img/station-4.png';
// import STATION_icon05 from 'assets/cairassets/img/station-5.png';
import STATION_icon00 from 'assets/cairassets/img/2x/Artboard 32@2x.png';
import STATION_icon01 from 'assets/cairassets/img/2x/Artboard 1@2x.png';
import STATION_icon02 from 'assets/cairassets/img/2x/Artboard 2@2x.png';
import STATION_icon03 from 'assets/cairassets/img/2x/Artboard 3@2x.png';
import STATION_icon04 from 'assets/cairassets/img/2x/Artboard 4@2x.png';
import STATION_icon05 from 'assets/cairassets/img/2x/Artboard 5@2x.png';

import { API_GET_AQI, API_GET_STATION, API_MAPLAYER } from 'configure/api.js';
import 'assets/cairassets/css/main.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stationLayer: L.layerGroup(),
      markerLayer: L.layerGroup(),
      currentLayer: L.layerGroup()
    };
  }

  componentDidMount() {
    // this.props.onRef(this);

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.map.scrollTop = 0;

    let self = this;
    var osm_map = L.tileLayer('https://tile.osm.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 27,
      minZoom: 5
    });

    var googleHybrid = L.tileLayer(
      'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
      {
        attribution: '&copy; <a href="#">Google</a> contributors',
        maxZoom: 27,
        minZoom: 5,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    );

    var googleStreets = L.tileLayer(
      'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        attribution: '&copy; <a href="#">Google</a> contributors',
        maxZoom: 27,
        minZoom: 5,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    );


    // get all station
    self.getStation();

    // WMS layer collections
    var airforthai = L.tileLayer.wms(API_MAPLAYER, {
      layers: 'airforthai',
      transparent: true,
      opacity: 0.5,
      format: 'image/png',
      maxZoom: 27,
      minZoom: 5
    });

    var airforthai3h = L.tileLayer.wms(API_MAPLAYER, {
      layers: 'airforthai3h',
      transparent: true,
      opacity: 0.5,
      format: 'image/png',
      maxZoom: 27,
      minZoom: 5
    });

    var allsensor = L.tileLayer.wms(API_MAPLAYER, {
      layers: 'allsensor',
      transparent: true,
      opacity: 0.5,
      format: 'image/png',
      maxZoom: 27,
      minZoom: 5
    });

    var allsensor3h = L.tileLayer.wms(API_MAPLAYER, {
      layers: 'allsensor3h',
      transparent: true,
      opacity: 0.5,
      format: 'image/png',
      maxZoom: 27,
      minZoom: 5
    });
    var airvisual = L.tileLayer.wms(API_MAPLAYER, {
      layers: 'airvisual',
      transparent: true,
      opacity: 0.5,
      format: 'image/png',
      maxZoom: 27,
      minZoom: 5
    });

    self.map = L.map(self.refs.map, {
      layers: [googleStreets]
    }).setView([13, 100], 8);

    let baseMaps = {
      'OSM Basemap': osm_map,
      'Google Hybridmap': googleHybrid,
      'Google Streetmap': googleStreets,

    };

    var groupedOverlays = {
      'PM2.5 concentration level': {
        'Air4Thai (1 hr)': airforthai,
        'Air4Thai (3 hr)': airforthai3h,
        'All Sensors (1 hr)': allsensor,
        'All Sensors (3 hr)': allsensor3h,
        //'Air Visual (1 hr)': airvisual
      }
    };

    var options = {
      // Make the "Landmarks" group exclusive (use radio inputs)
      // exclusiveGroups: ['PM2.5 concentration level'],
      // Show a checkbox next to non-exclusive group labels for toggling all
      groupCheckboxes: false
    };

    self.state.markerLayer.addTo(self.map);
    self.state.stationLayer.addTo(self.map);
    self.state.currentLayer.addTo(self.map);

    L.control.groupedLayers(baseMaps, groupedOverlays, options).addTo(self.map);

    self.map.on('click', function(e) {
      self.state.markerLayer.clearLayers();
      self.state.currentLayer.clearLayers();

      self.getAQI(e.latlng);
    });

    var legend = L.control({ position: 'bottomright' });

    function getColor(d) {
      return d > 200
        ? '#ec4e46'
        : d > 199
        ? '#f3a53b'
        : d > 99
        ? '#fffd55'
        : d > 49
        ? '#a0cd63'
        : d > 24
        ? '#68cafa'
        : '#FFEDA0';
    }

    legend.onAdd = function() {
      var div = L.DomUtil.create('div', 'info legend'),
        categories = ['0 - 25', '26 - 37', '38 - 50', '51 - 90', '>= 91'],
        value = [25, 50, 100, 200, 201],
        labels = [
          '<strong>PM<sub><small>2.5</small></sub> Level* (µg/m3)</strong>'
        ],
        //remark = ['<p class="legend_remark">* Thailand National Standard</p>'];
        remark = [''];

      var div = L.DomUtil.create('div', 'info legend');
      for (var i = 0; i < categories.length; i++) {
        div.innerHTML += labels.push(
          '<div class="circle" style="background:' +
            getColor(value[i]) +
            '"></div> ' +
            (categories[i] ? categories[i] : '+')
        );
      }
      div.innerHTML = labels.join('<br>');
      div.innerHTML += remark;
      return div;
    };

    legend.addTo(self.map);

    // L.easyButton(
    //   '<i class="material-icons resize-button">gps_fixed</i>',
    //   function(btn, map) {
    //     // helloPopup.setLatLng(map.getCenter()).openOn(map);
    //     map
    //       .locate({
    //         setView: false,
    //         watch: false
    //       })
    //       .on("locationfound", function(e) {
    //         self.getAQI(e.latlng);

    //         var circle = L.circle(
    //           [e.latitude, e.longitude],
    //           e.accuracy / 1000,
    //           {
    //             weight: 1,
    //             fillOpacity: 0.2
    //           }
    //         );
    //         self.state.markerLayer.clearLayers();
    //         self.state.currentLayer.clearLayers();
    //         self.map.setView(e.latlng, 18);
    //         self.state.currentLayer.addLayer(circle);
    //       });
    //   }
    // ).addTo(self.map);

    L.control.scale().addTo(self.map);

    var controlSearch = new L.Control.Search({
      // where do you want the search bar?
      url: 'https://nominatim.openstreetmap.org/search?format=json&q={s}',
      jsonpParam: 'json_callback',
      propertyName: 'display_name',
      propertyLoc: ['lat', 'lon'],
      marker: L.circleMarker([0, 0], { radius: 30 }),
      autoCollapse: true,
      autoType: false,
      minLength: 2,
      zoom: 15
    });

    self.map.addControl(controlSearch);
  }

  test() {}

  getCurrentPosition() {
    navigator.geolocation.getCurrentPosition(function(location) {
      var latlng = new L.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );
      alert(latlng);
    });
  }

  getStation() {
    var request = new Request(API_GET_STATION, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' })
    });

    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        //console.log(responseJson)
        switch (responseJson.status) {
          case 200:
            var geoJsonFeature = responseJson.data;
            var geoJsonData = L.geoJSON(geoJsonFeature, {
              //pm25val:function(feature, layer) {return feature.properties['pm25']},
              onEachFeature: function(feature, layer) {
                if (feature.properties) {
                  var str =
                    '<div class="popup_aqi"><div class="popup_content"><p class="popup_title">Station Name/Code: </p><p class="popup_value">' +
                    feature.properties['รหัสสถานี'] +
                    '</p></div><div class="popup_content"><p class="popup_title">PM<sub><small>2.5</small></sub> @Station (µg/m3): </p><p class="popup_value">' +
                    feature.properties['pm25'] +
                    '</p></div><div class="popup_content"><p class="popup_title">Update Time: </p><p class="popup_value">' +
                    moment(feature.properties['late_update']).format(
                      'DD/MM/YYYY HH'
                    ) +
                    ':00:00' +
                    '</p></div></div>';
                  return layer.bindPopup(str);
                }
              },
              pointToLayer: function(feature, latlng) {
                let aqi = feature.properties.pm25;
                let imgpath = '';

                if (!aqi) {
                  aqi = '-';
                  imgpath = STATION_icon00;
                }

                if (aqi <= 25) {
                  imgpath = STATION_icon01;
                } else if (aqi > 25 && aqi <= 37) {
                  imgpath = STATION_icon02;
                } else if (aqi > 37 && aqi <= 50) {
                  imgpath = STATION_icon03;
                } else if (aqi > 50 && aqi <= 90) {
                  imgpath = STATION_icon04;
                } else if (aqi > 91) {
                  imgpath = STATION_icon05;
                } else {
                  imgpath = STATION_icon00;
                }

                let myIcon = new L.icon({
                  iconUrl: imgpath,
                  iconSize: [45, 45],
                  shadowSize: [45, 65], // size of the shadow
                  iconAnchor: [25, 49], // point of the icon which will correspond to marker's location
                  shadowAnchor: [25, 55], // the same for the shadow
                  popupAnchor: [-2, -48] // point from which the popup should open relative to the iconAnchor
                });

                return L.marker(latlng, { icon: myIcon });
              }
            });
            //console.log(geoJsonData)
            var markers = L.markerClusterGroup({
            	iconCreateFunction: function(cluster) {
                var children = cluster.getAllChildMarkers();
                var sum = 0;
                //backgroundColorconsole.log(children[0])
                for (var i = 0; i < children.length; i++) {
                  //console.log(children[i].pm25val())
                  if( children[i].feature&&
                      children[i].feature.properties&&
                      children[i].feature.properties['pm25']){
                    sum += children[i].feature.properties['pm25'];

                  }
                }
                sum=Math.round(sum/children.length);
                function getColor(d) {
                  return d> 90
                    ? '#ec4e46'
                    : (d>=51 &&d <= 90)
                    ? '#f3a53b'
                    : (d>=38 &&d <= 50)
                    ? '#fffd55'
                    : (d>=26 &&d <= 37)
                    ? '#a0cd63'
                    : d <= 25
                    ? '#68cafa'
                    : '#FFEDA0';
                }
                var backgroundColor=getColor(sum);
             
                //console.log(backgroundColor)
                var html = 
            '<div class="icon" style="background-color:'+backgroundColor+';">' + sum + '</div>';
                return L.divIcon({ 
                  html: html,
                  className: '',
                  iconSize: L.point(25,25)  });
              }
            });
            markers.addLayer(geoJsonData).addTo(this.map);

            //this.state.stationLayer.addLayer(geoJsonData);
            //this.state.stationLayer.addLayer(geoJsonData);
            break;
          default:
            console.log('error');
            break;
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  getAQI(event) {
    var formData = new FormData();

    formData.append('lat', event.lat.toFixed(6));
    formData.append('lon', event.lng.toFixed(6));

    var request = new Request(API_GET_AQI, {
      method: 'POST',
      body: formData
    });

    fetch(request)
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson)
        switch (responseJson.status) {
          case 200:
            let aqi = responseJson.data;
            let status = '';
            let imgpath = '';
            let latlon = event.lat.toFixed(6) + ' , ' + event.lng.toFixed(6);
            let suggest = '';

            if (!aqi) {
              aqi = '-';
              status = '-';
              imgpath = AIR_icon00;
              suggest = '-';
            }

            if (aqi <= 25) {
              // status = 'Good';
              status = 'คุณภาพอากาศดี (Good)';
              imgpath = AIR_icon01;
              suggest = 'ทํากิจกรรมกลางแจ้งและท่องเที่ยวได้ตามปกติ';
            } else if (aqi > 25 && aqi <= 37) {
              // status = 'Moderate';
              status = 'คุณภาพอากาศปานกลาง (Moderate)';
              imgpath = AIR_icon02;
              suggest = 'ทํากิจกรรมกลางแจ้งและท่องเที่ยวได้ตามปกติ';
            } else if (aqi > 37 && aqi <= 50) {
              // status = 'Unhealthy for sensitive group';
              status = 'ไม่ปลอดภัยต่อสุขภาพของกลุ่มเสี่ยง (Unhealthy for sensitive group)';
              imgpath = AIR_icon03;
              suggest = 'ควรหลีกเลี่ยงการทํากิจกรรมหรือออกกําลังกายกลางแจ้ง';
            } else if (aqi > 50 && aqi <= 90) {
              // status = 'Unhealthy';
              status = 'ไม่ปลอดภัยต่อสุขภาพ (Unhealthy)';
              imgpath = AIR_icon04;
              suggest = 'ควรลดหรือจำกัดการทํากิจกรรมนอกบ้าน';
            } else if (aqi > 91) {
              // status = 'Very Unhealthy';
              status = 'ไม่ปลอดภัยต่อสุขภาพอย่างมาก (Very Unhealthy)';
              imgpath = AIR_icon05;
              suggest = 'ลดหรืองดการทํากิจกรรมนอกบ้าน';
            } else {
              status = 'No data..';
            }

            var greenIcon = L.icon({
              iconUrl: imgpath,
              iconSize: [45, 45], // size of the icon
              shadowSize: [45, 65], // size of the shadow
              iconAnchor: [25, 49], // point of the icon which will correspond to marker's location
              shadowAnchor: [25, 55], // the same for the shadow
              popupAnchor: [-2, -48] // point from which the popup should open relative to the iconAnchor
            });

            let marker_aqi = L.marker(event, { icon: greenIcon })
              .addTo(this.state.markerLayer)
              .bindPopup(
                '<div class="popup_aqi"><div class="popup_content"><p class="popup_title">Status: </p><p class="popup_value">' +
                  status +
                  '</p></div><div class="popup_content"><p class="popup_title">PM<sub><small>2.5</small></sub> (µg/m3): </p><p class="popup_value">' +
                  aqi +
                  '</p></div><div class="popup_content"><p class="popup_title">Lat Long: </p><p class="popup_value">' +
                  latlon +
                  // '</p></div><div class="popup_content"><p class="popup_title">Suggestion: </p><p class="popup_value">' +
                  // suggest +
                  '</p></div></div>'
              )
              .openPopup();
            this.state.markerLayer.addLayer(marker_aqi);
            break;
          default:
            console.log('error');
            break;
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  render() {
    return (
      <div
        ref="map"
        style={{
          height: '100vh',
          width: '100%'
        }}
      />
    );
  }
}

export default Map;
